
import { defineComponent, ref } from "vue";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification, ElLoading } from "element-plus";
export default defineComponent({
  name: "FilterHealthDashboard",
  mixins: [Globals],

  props: {

  },
  data() {


    const defaultTime2: [Date, Date] = [
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(),
    ]
    return {
      orgList: [],
      org: "",
      frequency: "",
      dateRange: "",
      defaultTime2


    }
  },
  mounted() {
    this.getApiProvider();

  },
  components: {},
  methods: {
    //cleare Date
    cleareDate() {
      if (this.frequency == "hourly") {
        console.log("date Range", this.dateRange)
        // this.dateRange =''
      }

    },
    //Disabled Dates
    dateDisabled(val) {
      if (val > new Date()) {
        return true;
      }

      if (this.frequency === 'daily') {
        const curruntDate = new Date();
        const oneMonthAgo = new Date(curruntDate);
        console.log("Date Before one Month", new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)), val, curruntDate)
        if (val < new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1))) {
          console.log("Inside Daily true conditions", val, val < new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)), new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)));
          return true;
        }

      }
    },
    //Apply Filter
    applyFilter() {
      console.log("Date Range In Apply Filter", this.dateRange)
     
      const Date1 = new Date(this.dateRange[0]);
      const Date2 = new Date(this.dateRange[1]);
      const year1 = new Date(Date1).getFullYear();
      const month1 = String(new Date(Date1).getMonth() + 1).padStart(2, "0");
      const day1 = String(new Date(Date1).getDate()).padStart(2, "0");
      const hour1 = Date1.getHours();
      const year2 = new Date(Date1).getFullYear();
      const month2 = String(new Date(Date1).getMonth() + 1).padStart(2, "0");
      const day2 = String(new Date(Date1).getDate()).padStart(2, "0");
      const hour2 = Date1.getHours();

      let Difference_In_Time = Date1.getTime() - Date2.getTime();
      let Difference_In_hours =Difference_In_Time / (1000 * 3600);


      console.log("🚀 ~ applyFilter ~ DiffinMilisecond:", this.defaultTime2.length,Difference_In_hours)
      if (this.selectedOrg == "" || this.frequency == "" || this.selectedApi == "") {
        ElNotification({
          title: 'Error',
          message: " Please Select Required Filters",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
    

      console.log("🚀 ~ applyFilter ~ DiffinMilisecond:", Date1, Date2, Date1.getTime(), Date2.getTime())
      if (this.dateRange == "" || this.org == "" || this.frequency == "") {
        ElNotification({
          title: 'Error',
          message: " Please Select Required Filters",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else if (this.frequency === "hourly" && Math.abs(Difference_In_hours) > 24) {
        ElNotification({
          title: 'Error',
          message: " Please Select 24 Hours time for Hourly",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else if(Date2 > new Date()){
        ElNotification({
          title: 'Error',
          message: " Please Select Valid Time",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else {
        this.$emit("setFilter", { dateRange: this.dateRange, org: this.org, frequency: this.frequency })
        console.log("Apply Filter", this.defaultTime2, this.org, this.frequency)
      }


    },
    //cleare Filter
    cleareFilter() {
      this.dateRange = "",
        this.org = '',
        this.frequency = ''

    },
    //getApi Provider Method
    getApiProvider() {
      ApiService.get(`/organizations`).then((data) => {
        this.orgList = data.data.data.organization_list;
        console.log("data Inside Tabel Sagar", data)
      }).catch((error) => {
        console.log("Error Inside Organization", error)
      })
    }

  },

});
