
import { defineComponent, onMounted } from "vue";
import { saveAs } from "file-saver";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Globals from "@/views/common/Globals.vue";
import FilterHealthDashboard from "./HealthDashboardFilter.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification, ElLoading } from "element-plus";
import alasql from "alasql";
import { identity } from "@fullcalendar/core";
import { eventBus } from "./EventBus";

export default defineComponent({
  name: "HealthDashboard",
  components: {
    FilterHealthDashboard
  },
  mixins: [Globals],
  data() {
    return {
      displayPage: 1,
      apiData: [],
      initApiData: [],
      searchQuery: "",
      searchFlag: true,
      pages: 1,
      totalCount: 1,
      rowPerPage: 10,
      page: 1,
      org: "1SB",
      fromDate: this.dateConverter(new Date().setHours(0, 0, 0)),
      toDate: this.dateConverter(new Date()),
      frequency: "hourly",
      apiFiltered: [] as any,
      bounderyValus: {} as any,
      day: [],
      hour: [],
      defaultDate:[new Date().setHours(0, 0, 0),new Date()]
    };
  },
  methods: {
    //emmitData BUs Event
     async emitData(orgData) {
  
        setTimeout(()=>{
         
          eventBus.emit("dataCatch", orgData);
           eventBus.all.delete("dataCatch")
        },1000);
   
      // eventBus.off('dataCatch');
    },
    //download
    downloads() {
      var keys = Object.keys(this.apiData[0]);
      let csv = "";
      csv += keys.join(",") + "\n";
      for (var line of this.apiData) {
        csv += keys.map((key) => JSON.stringify(line[key])) + "\n";
      }
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, "APIHealth.csv");
    },

    //setFilter
    setFilter(val) {
      this.defaultDate=val.dateRange,
      (this.org = val.org),
        (this.fromDate = this.dateConverter(val.dateRange[0])),
        (this.toDate = this.dateConverter(val.dateRange[1])),
        (this.frequency = val.frequency);
      this.getOrgApis();;
    },
    //dateConverter
    dateConverter(val) {
      const year = new Date(val).getFullYear();
      const month = String(new Date(val).getMonth() + 1).padStart(2, "0");
      const day = String(new Date(val).getDate()).padStart(2, "0");
      const hour = new Date(val).getHours();
      const minute = new Date(val).getMinutes();
      const second = new Date(val).getSeconds();
      return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    },
    dateConverter2(val) {
      const year = new Date(val).getFullYear();
      const month = String(new Date(val).getMonth() + 1).padStart(2, "0");
      const day = String(new Date(val).getDate()).padStart(2, "0");
      var monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return `${day} ${monthList[new Date(val).getMonth()]} ${year}`;
    },
    dateTimeConvertor(val) {
      const year = new Date(val.split("T")[0]).getFullYear();
      const month = String(new Date(val.split("T")[0]).getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(new Date(val.split("T")[0]).getDate()).padStart(
        2,
        "0"
      );
      var monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return `${day} ${
        monthList[new Date(val.split("T")[0]).getMonth()]
      } ${year} ${val.split("T")[1]}:00:00`;
    },
    //pageChange
    pageChange() {
      this.getOrgApis();
    },

    //seCurrentPage
    setCurrent(val) {
      if (this.page != val) {
        this.page = val;
        this.getOrgApis();
      }
    },
    //Search Api Data
    //Search Method
    searchMethod() {
      this.apiFiltered.splice(0, this.apiFiltered.length, ...this.initApiData);
      if (this.searchQuery !== "") {
        let results = [];
        for (let j = 0; j < this.apiFiltered.length; j++) {
          if (this.searchingFunc(this.apiFiltered[j], this.searchQuery)) {
            results.push(this.apiFiltered[j]);
          }
        }
        this.apiFiltered.splice(0, this.apiFiltered.length, ...results);
        if (this.apiFiltered.length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.apiFiltered.length != 0) {
          this.searchFlag = true;
        }
      }
    },
    //Search Function
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "number") &&
          !(typeof obj[key] === "object") &&
          !(typeof obj[key] === "boolean")
        ) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    //GetOrgAPI
    getOrgApis() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        `/organizations/${this.org}/apis?pageNumber=${this.page}&pageSize=${this.rowPerPage}&fromDate=${this.fromDate}&toDate=${this.toDate}&frequency=${this.frequency}`
      )
        .then((data) => {
          this.apiData = data.data.data.api_list;
          this.totalCount = data.data.data.total_count;
          this.pages = Math.ceil(this.totalCount / this.rowPerPage);

          this.day.length = 0;
          this.hour.length = 0;
          const result = alasql(
            "SELECT * ,GROUP_CONCAT(CONCAT(year,'/',month,'/',day)) AS ForDay,GROUP_CONCAT(CONCAT(year,'/',month,'/',day,'T',hour)) AS Hour,  GROUP_CONCAT(errorPercentage) AS ErrorPercentage ,GROUP_CONCAT(averageLatency) AS AverageLatency,GROUP_CONCAT(availabilityPercentage) AS AvailabilityPercentage FROM  ? GROUP BY id",
            [data.data.data.api_list]
          );
          this.apiFiltered.length = 0;
          for (let i = result[0].ForDay.split(",").length - 1; i > 0; i--) {
            this.day.push(
              this.dateConverter2(new Date(result[0].ForDay.split(",")[i]))
            );
          }
          if (this.frequency === "hourly") {
            for (let i = result[0].Hour.split(",").length -1 ; i > 0; i--) {
              this.hour.push(
                this.dateTimeConvertor(result[0].Hour.split(",")[i])
              );
            }
          }

          result.map((item) => {
            // this.hour =item.Hour.split(',');
            let combinedObject = {};
            let kpiArray = [];
            for (let i = item.AverageLatency.split(",").length - 1 ; i > 0 ; i--) {
              if (this.frequency === "daily") {
                combinedObject = {
                  averageLatency: item.AverageLatency.split(",")[i],
                  availabilityPercentage:
                    item.AvailabilityPercentage.split(",")[i],
                  errorPercentage: item.ErrorPercentage.split(",")[i],
                };
              } else {
                combinedObject = {
                  averageLatency: item.AverageLatency.split(",")[i],
                  availabilityPercentage:
                    item.AvailabilityPercentage.split(",")[i],
                  errorPercentage: item.ErrorPercentage.split(",")[i],
                };
              }
              kpiArray.push(combinedObject);
            }
            this.apiFiltered.push({
              id: item.id,
              organizationCode: item.organizationCode,
              apiGroup: item.apiGroup,
              apiEndpointUrl: item.apiEndpointUrl,
              days: item.ForDay.split(","),
              hours: item.Hour.split(","),
              errorPercentage: item.ErrorPercentage.split(","),
              averageLatency: item.AverageLatency.split(","),
              availabilityPercentage: item.AvailabilityPercentage.split(","),
              kpi: kpiArray,
            });
          });
          this.bounderyValus = data.data.data.standardKPI;
          this.initApiData = [...this.apiFiltered];
          loading.close();
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
    },

    scrollLeft(event) {
      // document.querySelector('.table-responsive').scrollTo({
      //   left: 100,
      //   behavior: 'smooth'
      // });
      let content = document.querySelector(".table-responsive");
      content.scrollLeft -= 120;
    },
    scrollRight(event) {

      // document.querySelector('.table-responsive').scrollTo({
      //   left: +100,
      //   behavior: 'smooth'
      // });
      let content = document.querySelector(".table-responsive");
      content.scrollLeft += 120;
    },
  },
  created() {
    this.emitData({});
  },
  mounted() {
    this.getOrgApis();
  },
  setup() {
    onMounted(() => {

      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("API Health Dashboard", ["API Insight"]);
    });
  },
});
